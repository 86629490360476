import React  from "react";
import { makeStyles } from "@mui/styles";
import { Modal } from "@mui/material";
import logo from "../assets/icons/deBotLogoTransparent.png";

export const LogoutConfirmationModal = ({ isOpen, handleCloseModal, logout, authenticate }) => {
  const classes = useStyles();

  return (
    <Modal className={classes.modal} open={isOpen}>
      <div className={classes.modalContainer}>
        <div className={classes.infoContainer}>
          <div className={classes.headerLogo}>
          <img src={logo} style={{width: 250, marginLeft: -30, marginTop: 4,}} alt="DebotBSC Logo" />
          </div>
          <h2 className={classes.subTitle}>Are you sure you want to log out ?</h2>
          <div
            style={{
              height: 1,
              width: "100%",
              background: "gray",
              marginBottom: 30,
              marginTop: 8,
            }}
          ></div>

          <div className={classes.horizContainer}>
            <div className={classes.control} style={{ background: "#f84b67" }} onClick={() => logout().then(() => window.location.reload(false))}>
              <h2 className={classes.controlText}>Logout</h2>
            </div>
            <div
              className={classes.control}
              style={{ background: "#3c3e53" }}
              onClick={handleCloseModal}
            >
              <h2 className={classes.controlText}>Cancel</h2>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const useStyles = makeStyles({
    headerLogo: {
        marginTop: 20,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      },
      modal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      modalContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        height: "33vh",
        width: "35vw",
        background: "#27293d",
        borderRadius: 12,
      },
      closeButton: {
        position: "absolute",
        height: 50,
        width: 50,
        borderRadius: 100,
        backgroundColor: "tomato",
        top: 20,
        right: 20,
      },
      input: {
        color: "white",
        width: "95%",
        fontSize: 16,
        padding: 14,
        border: "none",
        background: "#242535",
        borderRadius: 8,
        marginTop: 8,
        marginBottom: 8,
      },
      largeInput: {
        color: "white",
        width: "95%",
        fontSize: 16,
        padding: 14,
        border: "none",
        background: "#242535",
        borderRadius: 8,
        marginTop: 8,
        marginBottom: 8,
      },
      formContainer: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      },
      launchSelector: {
        marginTop: 16,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: 530,
        height: 50,
      },
      selectedLaunchButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "48%",
        background: "tomato",
      },
      launchButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "48%",
        border: "2px solid tomato",
      },
      buttonText: {
        color: "white",
        fontSize: 16,
        textTransform: "uppercase",
        letterSpacing: 6,
      },
      submitButton: {
        marginTop: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "tomato",
        width: 440,
        height: 60,
      },
      infoContainer: {
        height: "90%",
        width: "85%",
      },
      selectionContainer: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      },
      title: {
        marginTop: 15,
        color: "white",
        fontSize: 42,
        fontWeight: "600",
      },
      subTitle: {
        color: "white",
        fontWeight: "300",
        marginTop: 20,
      },
      description: {
        marginBottom: 20,
        fontSize: 18,
        fontWeight: "400",
        color: "white",
      },
      selectionButton: {
        marginTop: 30,
        height: 300,
        width: "49%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#31334c",
        borderRadius: 12,
      },
      selectionText: {
        color: "white",
        fontWeight: "400",
      },
      spreadForm: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexWrap: "wrap",
        height: 150,
        width: "80%",
        marginTop: 15,
      },
      smInputContainer: {
        width: "40%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginRight: 50,
      },
      smInput: {
        color: "white",
        width: "30%",
        fontSize: 16,
        padding: 14,
        border: "none",
        background: "#242535",
        borderRadius: 8,
        marginTop: 8,
        marginBottom: 8,
      },
      smInputLabel: {
        color: "gray",
        fontWeight: "300",
        marginRight: 8,
        width: 85,
      },
      horizContainer: {
        marginTop: 40,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      control: {
        width: "48%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 60,
        cursor: 'pointer',
      },
      controlText: {
        color: "white",
        fontWeight: "400",
      },
});
