import React, { useEffect, useState } from "react";
import { useAlert } from 'react-alert'
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { useMoralisQuery, useMoralisSubscription, useMoralis } from "react-moralis";
import { Timer } from "./Timer";
import { Manual } from "./Manual";
import { Captcha } from "./Captcha";
import { HeaderTab } from "./HeaderTab";
import { BallTriangle } from "react-loader-spinner";
import websiteIcon from "../assets/icons/websiteIcon.png";
import telegramIcon from "../assets/icons/telegramIcon.png";
import poocoinIcon from "../assets/icons/poocoinIcon.png";
import honeypotIcon from "../assets/icons/honepot.png";
import chatIcon from "../assets/icons/chatIcon.png";
import rocketIcon from "../assets/icons/rocketshipIcon.png";
import exclamationIcon from "../assets/icons/exclamationIcon.png";

export default function Launch() {
  const classes = useStyles();

  const alert = useAlert()

  const [project, setProject] = useState({});

  const [isRevealed, setIsRevealed] = useState(false);
  const [isSuccessCaptcha, setIsSuccessCaptcha] = useState(false);

  let { id } = useParams();

  const {Moralis} = useMoralis()

  const { data, isFetching } = useMoralisQuery(
    "Launches",
    (query) => query.equalTo("objectId", id),
    [],
    {
      live: true,
    }
  );

  useMoralisSubscription(
    "Launches",
    (query) => query.equalTo("objectId", id),
    [],
    {
      onUpdate: (data) =>
        alert.show(`${data.attributes.tokenName} was successfully launched!!`),
    }
  );

  const getDetails = async (data) => {
    if (!data) {
      return [];
    } else {
      data.map((launch) => {
        setProject({
          tokenName: launch.attributes.tokenName,
          tokenHash:
            launch.attributes.isLaunched === false
              ? ""
              : launch.attributes.tokenHash,
          launchType: launch.attributes.launchType,
          tokenSymbol: launch.attributes.tokenSymbol,
          launchDate: launch.attributes.launchDate,
          tokenWebsite: launch.attributes.tokenWebsite,
          tokenTelegram: launch.attributes.tokenTelegram,
          tokenDescription: launch.attributes.tokenDescription,
          buyTax: launch.attributes.buyTax,
          sellTax: launch.attributes.sellTax,
          maxTX: launch.attributes.maxTX,
          maxWallet: launch.attributes.maxWallet,
          isLaunched: launch.attributes.isLaunched,
        });
      });
    }
  };

  const getPair = async() => {
    const results = await Moralis.Web3API.account.getTokenTransfers({
      chain: 'bsc',
      address: '0x8383209268b9ab89f26e359cdaf364dbb4cd63b3',
      order: 'asc',
    })
    if (results){
      console.log(results)
    }
  }

  const handleReveal = () => {
    setIsRevealed(true);
  };

  const revealContract = () => {
    setIsSuccessCaptcha(true);
  };

  useEffect(() => {
    getDetails(data);
  }, [data]);

  if (!id) {
    return <h2>{id ? console.log(id) : "error"}</h2>;
  }

  return (
    <div className={classes.container}>
      <div className={classes.dashboard}>
        <HeaderTab />
        {isFetching ? (
          <div style={{ position: "absolute", top: 400 }}>
            <BallTriangle width={180} color="#f84b67" arialLabel="loading" />
          </div>
        ) : (
          <>
            <div
              className={classes.widgetContainer}
              style={{
                position: "absolute",
                height: "58%",
                width: "60%",
                top: 100,
                left: 0,
              }}
            >
              <div className={classes.horizContainer}>
                <h2 className={classes.widgetTitle}>
                  <span className={classes.tokenSymbol}>
                    ({project.tokenSymbol})
                  </span>
                  {project.tokenName}
                </h2>
                <div
                  className={classes.icon}
                  onClick={() => getPair()}
                >
                  <img
                    src={websiteIcon}
                    style={{ height: "100%" }}
                    alt="Website Icon"
                  />
                </div>
                <div
                  className={classes.icon}
                  onClick={() =>
                    window.open(project.tokenTelegram)
                  }
                >
                  <img
                    src={telegramIcon}
                    style={{ height: "100%" }}
                    alt="Telegram Icon"
                  />
                </div>
              </div>
              <h2 className={classes.widgetSubTitle}>Token Description:</h2>
              <p className={classes.widgetDescription}>
                {project.tokenDescription}
              </p>
              <h2 className={classes.launchType}>
                {project.launchType} Launch:
              </h2>
              <div className={classes.widgetInfo}>
                <p className={classes.infoText}>Buy Tax:</p>
                <p className={classes.infoResponse}>{project.buyTax} %</p>
              </div>
              <div className={classes.widgetInfo}>
                <p className={classes.infoText}>Sell Tax:</p>
                <p className={classes.infoResponse}>{project.sellTax} %</p>
              </div>
              <div className={classes.widgetInfo}>
                <p className={classes.infoText}>Max Buy:</p>
                <p className={classes.infoResponse}>{project.maxTX != '' ? `${project.maxTX} %` : 'N/A'}</p>
              </div>
              <div className={classes.widgetInfo}>
                <p className={classes.infoText}>Max Wallet</p>
                <p className={classes.infoResponse}>{project.maxWallet != '' ? `${project.maxWallet} %` : 'N/A'}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: 50,
                  marginTop: 40,
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <img
                  src={exclamationIcon}
                  style={{ height: 40, marginRight: 20 }}
                  alt="Warning Icon"
                />
                <p className={classes.subText}>
                  All token information is provided by the launch page creators.
                  Anyone can create a launch page for their token. Make sure the
                  launch page you follow was directly shared by the projects
                  team in one of their official social media platforms.
                </p>
              </div>
            </div>
            <div
              className={classes.widgetContainer}
              style={{
                position: "absolute",
                height: "26%",
                width: "60%",
                bottom: 15,
                left: 0,
              }}
            >
              <div className={classes.launchContainer}>
                <div
                  className={classes.horizContainer}
                  style={{ marginTop: 10 }}
                >
                  <h2 className={classes.subTitle}>Contract Launch:</h2>
                  {project.isLaunched === false ? (
                    project.launchType === "Stealth" ? (
                      <h2 className={classes.pendingText}>
                        Contract Pending...
                      </h2>
                    ) : (
                      <Timer
                        timer={project.launchDate}
                        handleReveal={handleReveal}
                      />
                    )
                  ) : (
                    <Manual
                      isRevealed={false}
                      projectHash={project.tokenHash}
                      handleReveal={handleReveal}
                    />
                  )}
                </div>
              </div>
              <p
                style={{
                  color: "gray",
                  fontSize: 16,
                  marginLeft: 50,
                  marginTop: 10,
                  width: "90%",
                }}
              >
                The contract address will be available to reveal once the
                projects dev manually allow it. To reveal the contract simply
                click the "Reveal Contract" button that will appear once the
                contract is made available by the projects team.
              </p>
              {isRevealed === false ? null : !isSuccessCaptcha ? (
                <Captcha revealContract={revealContract} />
              ) : (
                <div className={classes.contractContainer}>
                  <div className={classes.contract}>
                    <p>{project.tokenHash}</p>
                  </div>
                  <div className={classes.buttonContainer}>
                    <div
                      className={classes.toolButton}
                      onClick={() =>
                        window.open(
                          `https://poocoin.app/tokens/${project.tokenHash}`
                        )
                      }
                    >
                      <img
                        src={poocoinIcon}
                        style={{ height: 20, marginRight: 10 }}
                        alt="Warning Icon"
                      />
                      <p>View Chart</p>
                    </div>
                    <div
                      className={classes.toolButton}
                      onClick={() =>
                        window.open(
                          `https://honeypot.is/?address=${project.tokenHash}`
                        )
                      }
                    >
                      <img
                        src={honeypotIcon}
                        style={{ height: 20, marginRight: 10 }}
                        alt="Warning Icon"
                      />
                    <p>Check Honeypot</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className={classes.widgetContainer}
              style={{
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "42%",
                width: "38.5%",
                top: 100,
                right: 0,
              }}
            >
              <div className={classes.featureContainer}>
                <h2 className={classes.subTitle}>
                  Token Chat{" "}
                  <img
                    src={chatIcon}
                    style={{ height: 25, marginLeft: 5 }}
                    alt="Chat Icon"
                  />
                </h2>
                <p className={classes.widgetDescription}>
                  Unfiltered, and unmodify-able by the tokens developer. Users
                  must connect their metamask wallet in order to make comments.
                  This is to avoid bots and spam.
                </p>
                <div className={classes.extraContainer}>
                  <h2 className={classes.extraText}>Coming Soon</h2>
                </div>
              </div>
            </div>
            <div
              className={classes.widgetContainer}
              style={{
                position: "absolute",
                display: " flex",
                justifyContent: "center",
                alignItems: "center",
                height: "42%",
                width: "38.5%",
                bottom: 15,
                right: 0,
              }}
            >
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  icon: {
    marginTop: 30,
    marginLeft: 10,
    height: 40,
    width: 40,
    borderRadius: 100,
    background: "#1e1e26",
    cursor: "pointer",
  },
  horizContainer: {
    display: "flex",
    alignItems: "center",
  },
  dashboard: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "95%",
    width: "97%",
    borderRadius: 8,
  },
  widgetContainer: {
    background: "#27293d",
    borderRadius: 8,
  },
  widgetTitle: {
    marginTop: 20,
    marginLeft: 50,
    marginRight: 10,
    color: "white",
    fontSize: 40,
    fontWeight: "700",
  },
  tokenSymbol: {
    color: "#f84b67",
    marginRight: 10,
  },
  widgetSubTitle: {
    display: "flex",
    fontSize: 32,
    fontWeight: "700",
    color: "white",
    marginLeft: 50,
    marginTop: 10,
  },
  widgetDescription: {
    color: "gray",
    fontSize: 16,
    width: "85%",
    fontWeight: "400",
    marginLeft: 50,
    marginTop: 10,
  },
  launchType: {
    color: "white",
    marginLeft: 50,
    marginTop: 20,
  },
  widgetInfo: {
    display: "flex",
    justifyContent: "space-between",
    width: "92%",
    marginLeft: 50,
    padding: "5px 0",
    borderBottom: "1px solid gray",
    marginTop: 10,
  },
  infoText: {
    color: "white",
    fontWeight: "300",
    fontSize: 17,
  },
  infoResponse: {
    color: "lightgreen",
    fontSize: 17,
    fontWeight: "300",
    paddingRight: 20,
  },
  subText: {
    color: "gray",
    fontSize: 16,
    width: "80%",
  },
  subTitle: {
    color: "white",
    marginLeft: 50,
  },
  launchContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  pendingText: {
    color: "#f84b67",
    fontWeight: "500",
    marginLeft: 20,
  },
  revealContainer: {
    marginLeft: 50,
    marginTop: 10,
  },
  contractContainer: {
    marginTop: 15,
    display: "flex",
    flexDirection: "column",
    marginLeft: 50,
    width: "40%",
  },
  contract: {
    padding: 8,
    background: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "silver",
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  toolButton: {
    marginTop: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontSize: 18,
    height: 45,
    width: "48.5%",
    background: "#f84b67",
  },
  featureContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "85%",
    width: "100%",
    color: "white",
    borderRadius: 8,
  },
  extraContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 30,
    width: "85%",
    marginLeft: 50,
    height: 200,
    background: "#1e1e26",
    borderRadius: 8,
  },
  extraText: {
    color: "gray",
    fontWeight: "300",
    textTransform: "uppercase",
    letterSpacing: 8,
  },
});
