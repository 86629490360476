import React from "react";
import { makeStyles } from "@mui/styles";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";
import RedditIcon from "@mui/icons-material/Reddit";
import heroBackground from '../assets/DebotWebsiteBackground.png'
import heroImage from '../assets/DebotDevicesImage.png'
import { Link } from "react-router-dom";
import logo from "../assets/icons/deBotLogoTransparent.png";

export default function Landing() {
  const classes = useStyles();
  return (
    <div className={classes.container} style={{ backgroundImage: `url(${heroBackground})`}}>
      <div className={classes.header}>
        <Link className={classes.logoContainer} to="/dashboard">
          <img src={logo} style={{width: 250}} />
        </Link>
        <div className={classes.socialContainer}>
          <TelegramIcon className={classes.socialIcon} onClick={() => window.open('https://t.me/debotbsc')} />
          <TwitterIcon className={classes.socialIcon} onClick={() => window.open('https://Twitter.com/DeBotBSC')}/>
          <RedditIcon className={classes.socialIcon} onClick={() => window.open('https://Reddit.com/DeBotBSC')} />
        </div>
      </div>
      <div className={classes.heroContainer}>
        <div className={classes.flexContainer}>
          <div className={classes.infoContainer}>
            <h2 className={classes.title}><span className={classes.accent}>Rid </span> Your Launch of <span className={classes.accent}>Bots</span> & <span className={classes.accent}>Snipers</span></h2>
            <p className={classes.subText}>
              Protect your launch from bots and snipers. Release your contract
              address behind a secure anti-bot captcha that users must complete
              in order to reveal the contract address of your Stealth or Fair
              launch.
            </p>
            <div className={classes.buttonContainer}>
                <Link to="/dashboard" className={classes.button} style={{background: '#f84b67'}}>
                    <p className={classes.buttonText}>Open App</p>
                </Link>
                <div className={classes.button} style={{background: '#24245b'}} onClick={() => window.open('https://t.me/thebnbboys')}>
                    <p className={classes.buttonText}>Join Our Telegram</p>
                </div>
            </div>
          </div>
        </div>
        <div className={classes.flexContainer}>
            <div className={classes.imageContainer}>
                <img className={classes.heroImage} src={heroImage} alt="Background"/>
            </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
    heroImage:{
        width: 750,
    },
    title:{
        color: 'white',
        fontSize: 64,
        fontWeight: '700',
    },
    subText:{
        marginTop: 15,
        marginBottom: 40,
        color: 'white',
        fontSize: 20,
        fontWeight: '300',
    },
  socialIcon: {
    cursor: 'pointer',
    color: "#f84b67",
    marginLeft: 12,
    marginRight: 12,
    fontSize: "48px !important",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
     
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "12vh",
    width: "100%",
  },
  logoContainer: {
    height: "100%",
    width: "20vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  socialContainer: {
    height: "100%",
    width: "20vw",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heroContainer: {
    height: "88vh",
    width: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    height: "100%",
    width: "50%",
   marginBottom: 50,

  },
  imageContainer: {
    height: "100%",
    width: "50%",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoContainer:{
      height: '70%',
      width :'85%',
      display :'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',

  }, 
  button:{
      display: 'flex',
      justifyContent: 'center',
      alignItems:'center',
        height: 55,
        padding: '0px 30px',
      borderRadius: 4,
      marginRight: 30,
      textDecoration: 'none', 
      cursor: 'pointer',
  }, 
  buttonText:{
      color: 'white',
      fontSize: 18,
  }, 
  buttonContainer:{
      display: 'flex',
  },
  accent:{
      color: '#f84b67'
  }
});
