import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { HeaderTab } from "./HeaderTab";
import { useMoralisQuery } from "react-moralis";

export const Launches = () => {

  const { data } = useMoralisQuery(
    "Launches",
    (query) => query.descending("createdAt"),
    [],
    {
      live: true,
    }
  );

  const classes = useStyles();

  useEffect(() => {
      
  }, [data])

  return (
    <div className={classes.container}>
      <div className={classes.dashboard}>
        <HeaderTab />
        <div className={classes.tempContainer}>
          <h2 style={{fontSize: 48, color: 'gray', fontWeight: '300', textTransform: 'uppercase', marginBottom: 50, letterSpacing: 12}}>Coming soon</h2>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  dashboard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "95%",
    width: "97%",
    borderRadius: 8,
  },
  tempContainer:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
    height: '41vw',
    width: '100%',
    background: '#27293d',
    borderRadius: 8,
  }
});
