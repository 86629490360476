import { Routes, Route } from "react-router-dom";
import { makeStyles} from '@mui/styles';
import DashBoard from "./components/DashBoard";
import Launch from "./components/Launch";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Landing from "./components/Landing";
import { useEffect } from "react";
import { Launches } from "./components/Launches";


function App() {
  const classes = useStyles();

  useEffect(() => {
  }, [])

  return (
    <div className={classes.appContainer}>

      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/launches" element={<Launches />} />
        <Route path="/launches/:id" element={<Launch />} />
        <Route path="/dashboard" element={<DashBoard />} />
        
      </Routes>
    </div>
  );
}

const useStyles = makeStyles({
  appContainer:{
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100vh',
    width :'100vw',
    background: '#1e1e26',
  }
})

export default App;
