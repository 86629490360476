import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { LaunchFormModal } from "./LaunchFormModal";
import { useMoralisQuery } from "react-moralis";
import { Link } from "react-router-dom";
import { useAlert } from 'react-alert'
import AddIcon from "@mui/icons-material/Add";
import { Timer } from "./Timer";
import { ConfirmationModal } from "./ConfirmationModal";
import { BallTriangle } from "react-loader-spinner";
import stealthIcon from "../assets/icons/stealthIcon.png";
import fairIcon from "../assets/icons/fairIcon.png";

export const LaunchVault = ({ user }) => {
  const classes = useStyles();

  const alert = useAlert();

  const { data, error, isLoading, isFetching } = useMoralisQuery(
    "Launches",
    (query) => query.equalTo("userId", user.id)
  );

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [project, setProject] = useState({});

  const handleModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleConfirmation = (launch) => {
    setIsOpenConfirmation(true);
    setProject(launch);
  };

  const handleCloseConfirmation = () => {
    setIsOpenConfirmation(false);
  };

  const RenderActiveLaunches = ({ launches }) => {
    return launches.map((launch) => {
      if (launch.attributes.isLaunched === false) {
        return null;
      } else {
        return (
          <div className={classes.activeLaunchContainer}>
            <div
              className={classes.horizContainer}
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <div className={classes.horizContainer}>
                <img
                  src={
                    launch.attributes.launchType === "Stealth"
                      ? stealthIcon
                      : fairIcon
                  }
                  alt="Selection Icon"
                  style={{ width: 25, marginRight: 8 }}
                />
                <h2 className={classes.launchTitle}>
                  {launch.attributes.launchType} Launch
                </h2>
              </div>
              <h2 className={classes.successText}> Active </h2>
            </div>
            <div className={classes.horizContainer}>
              <h2 className={classes.tokenName}>
                <span className={classes.tokenSymbol}>
                  ({launch.attributes.tokenSymbol})
                </span>
                {launch.attributes.tokenName}
              </h2>
            </div>
            <p className={classes.tokenDescription}>
              {`${(launch.attributes.tokenDescription).substring(0, 250)}...`}
            </p>
            <div className={classes.controlContainer}>
              <Link className={classes.control} to={`../launches/${launch.id}`}>
                <h2 className={classes.buttonText}>View Page</h2>
              </Link>
            </div>
          </div>
        );
      }
    });
  };

  const RenderLaunches = ({ launches }) => {
    return launches.map((launch) => {
      if (launch.attributes.isLaunched === true) {
        return null;
      } else {
        return (
          <div className={classes.launchContainer}>
            <div
              className={classes.horizContainer}
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <div className={classes.horizContainer}>
                <img
                  src={
                    launch.attributes.launchType === "Stealth"
                      ? stealthIcon
                      : fairIcon
                  }
                  alt="Selection Button"
                  style={{ width: 25, marginRight: 8 }}
                />
                <h2 className={classes.launchTitle}>
                  {launch.attributes.launchType} Launch
                </h2>
              </div>
              <div style={{cursor: 'pointer'}} onClick={() => {
                navigator.clipboard.writeText(launch.attributes.tgBotKey)
                alert.show(`Copied to clipboard`)
              }}>
                <h2 className={classes.pendingText}> {launch.attributes.tgBotKey}</h2>
              </div>
            </div>
            <div className={classes.horizContainer}>
              <h2 className={classes.tokenName}>
                <span className={classes.tokenSymbol}>
                  ({launch.attributes.tokenSymbol})
                </span>
                {launch.attributes.tokenName}
              </h2>
            </div>
            <p numberOfLines={2} className={classes.tokenDescription}>
              {`${(launch.attributes.tokenDescription).substring(0, 250)}...`}
            </p>
            <div className={classes.controlContainer}>
              <Link className={classes.control} to={`../launches/${launch.id}`}>
                <h2 className={classes.buttonText}>View Page</h2>
              </Link>
              {launch.attributes.launchType === "Stealth" ? (
                <div
                  className={classes.manualLaunchControl}
                  onClick={() => handleConfirmation(launch)}
                >
                  <h2 className={classes.buttonText}>Reveal</h2>
                </div>
              ) : (
                <div className={classes.control}>
                  <Timer small timer={launch.attributes.launchDate} />
                </div>
              )}
            </div>
          </div>
        );
      }
    });
  };

  if (error) {
    return (
      <>
        <h2>error</h2>
        {console.log("error", error)}
      </>
    );
  }
  if (isLoading) {
    return <h2>loading...</h2>;
  }
  return (
    <div className={classes.launchVault}>
      <LaunchFormModal isOpen={isOpen} handleCloseModal={handleCloseModal} />
      <ConfirmationModal
        isOpen={isOpenConfirmation}
        projectToLaunch={project}
        projectId={project.id}
        handleCloseModal={handleCloseConfirmation}
      />
      <div className={classes.launches}>
        <div className={classes.horizContainer}>
          <div className={classes.addButton} onClick={handleModal}>
            <AddIcon className={classes.icon} />
            <h2 className={classes.buttonLabel}>Create a Launch</h2>
          </div>
          {!isFetching ? (
            <div
              className={classes.flexContainer}
              onWheel={(event) => {
                const delta = Math.max(
                  -1,
                  Math.min(
                    1,
                    event.nativeEvent.wheelDelta || -event.nativeEvent.detail
                  )
                );
                event.currentTarget.scrollLeft -= delta * 50;
                event.preventDefault();
              }}
            >
              <RenderLaunches launches={data ? data : []} />
            </div>
          ) : (
            <div className={classes.loadingContainer}>
              <BallTriangle width={90} color="#f84b67" arialLabel="loading" />
            </div>
          )}
        </div>
        <div
          style={{
            height: 1,
            width: "100%",
            background: "gray",
            opacity: 0.3,
            marginTop: 20,
          }}
        ></div>
        <h2 className={classes.subTitle}>Successful Launches</h2>
        <div className={classes.horizContainer}>
          {!isFetching ? (
            <RenderActiveLaunches launches={data ? data : []} />
          ) : (
            <div className={classes.loadingContainer}>
              <BallTriangle width={90} color="#f84b67" arialLabel="loading" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  flexContainer: {
    display: "flex",
    height: "100%",
    maxWidth: "80vw",
    flexWrap: "nowrap",
    overflowX: "hidden",
  },
  launchVault: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  subTitle: {
    marginTop: 20,
    color: "white",
    fontWeight: "400",
  },
  title: {
    color: "#0A0226",
    marginLeft: 40,
    marginTop: 30,
    marginBottom: 30,
    fontWeight: "700",
    fontSize: 50,
    textTransform: "uppercase",
  },
  divider: {
    height: 1,
    width: "100%",
    background: "#0A0226",
  },
  launches: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flex: 1,
    width: "100%",
  },
  launchContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: 30,
    padding: 20,
    height: 300,
    minWidth: 300,
    maxWidth: 300,
    background: "#27293d",
    borderRadius: 12,
    marginRight: 40,
    textDecoration: "none",
  },
  activeLaunchContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: 20,
    padding: 20,
    height: 300,
    width: 300,
    background: "#27293d",
    borderRadius: 12,
    marginRight: 40,
    textDecoration: "none",
  },
  loadingContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center ",
    marginTop: 30,
    padding: 20,
    height: 300,
    width: 300,
    background: "#27293d",
    borderRadius: 12,
    marginRight: 40,
    textDecoration: "none",
  },
  addButton: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 30,
    padding: 20,
    height: 300,
    minWidth: 300,
    background: "#27293d",
    borderRadius: 12,
    marginRight: 40,
    textDecoration: "none",
    cursor: "pointer",
  },
  tokenName: {
    marginTop: 20,
    color: "white",
    marginBottom: 16,
    fontWeight: "700",
    fontSize: 20,
    textDecoration: "none !important",
  },
  tokenSymbol: {
    color: "#f84b67",
    fontWeight: "700",
    fontSize: 20,
    textDecoration: "none !important",
    marginBottom: 4,
    marginRight: 8,
  },
  tokenHash: {
    position: "absolute",
    bottom: 20,
    color: "gray",
    fontSize: 16,
    fontWeight: "300",
    textDecoration: "none !important",
  },
  icon: {
    color: "white",
    fontSize: "150px !important",
    fontWeight: "300 !important",
  },
  buttonLabel: {
    position: "absolute",
    bottom: 40,
    color: "white",
    fontSize: 16,
    fontWeight: "400",
    letterSpacing: 5,
    textTransform: "uppercase",
  },
  horizContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  launchTitle: {
    color: "white",
    fontSize: 16,
    fontWeight: "700",
  },
  pendingText: {
    color: "lightgreen",
    fontSize: 14,
    fontWeight: "600",
  },
  successText: {
    color: "lightgreen",
    fontSize: 14,
    fontWeight: "600",
  },
  tokenDescription: {
    color: "white",
    fontSize: 16,
    fontWeight: "300",
  },
  controlContainer: {
    position: "absolute",
    bottom: 10,
    width: "90%",
    height: 80,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  control: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "48%",
    height: 50,
    borderRadius: 8,
    background: "#3c3e53",
    textDecoration: 'none',
  },
  manualLaunchControl: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "48%",
    height: 50,
    borderRadius: 8,
    background: "#f84b67",
    cursor: "pointer",
  },
  buttonText: {
    color: "white",
    fontWeight: "500",
    fontSize: 18,
    textDecoration: 'none',
  },
});
