import React, { useState } from 'react'
import {makeStyles} from "@mui/styles"
import { useMoralis } from 'react-moralis';
import logo from '../assets/icons/deBotLogoTransparent.png'
import { LogoutConfirmationModal } from './LogoutConfirmationModal';
import { Link } from 'react-router-dom';

export const HeaderTab = () => {
    const classes = useStyles()

    const [isOpen, setIsOpen] = useState(false)

    const { authenticate, isAuthenticated, user, logout } = useMoralis();

    const handleOpenModal = () => {
        setIsOpen(true)
    }

    const handleCloseModal = () => {
        setIsOpen(false)
    }

    return (
        <div className={classes.container}>
            <LogoutConfirmationModal isOpen={isOpen} handleCloseModal={handleCloseModal}  logout={logout} />
            <Link className={classes.headerLogo} to="/dashboard">
                <img src={logo} style={{width: 200, marginLeft: 15, marginTop: 4,}} alt="Header Logo" />
            </Link>
            <div className={classes.wallet}>
                <div className={classes.walletContainer} onClick={isAuthenticated ? () => handleOpenModal() : () => authenticate()}>
                    <p>{!isAuthenticated ? 'Guest' : `${(user.attributes.ethAddress).substring(0,22)}...`}</p>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles({
    container:{
        height: 70,
        width: '100%',
        background: '#27293d',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 8,
    },
    headerLogo:{
        display: 'flex',
        justifyContent:'flex-start',
        alignItems: 'center',
        height :'100%',
        width: '30%',
    },
    walletContainer:{
        background: '#f84b67',
        padding: '12px 20px',
        borderRadius: 8,
        color: 'white',
        fontWeight: '400',
        cursor: 'pointer',
    },
    wallet:{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: 16,
        height: '100%',
        width: '15%',
    }
})
