import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";

export const Captcha = ({ revealContract }) => {
  
  useEffect(() => {
    loadCaptchaEnginge(5);
  }, []);

  const classes = useStyles();

  const [captchaText, setCaptchaText] = useState("");

  const launchSetup = () => {
    if (validateCaptcha(captchaText, false) === true) {
      revealContract()
    } else {
      alert(" Captcha did not match. Please try again");
    }
  };

  return (
    <div className={classes.container}>
      <LoadCanvasTemplateNoReload
        className={classes.captcha}
      />
      <div className={classes.horizContainer}>
        <input
          placeholder="Enter Captcha"
          className={classes.input}
          onChange={(e) => setCaptchaText(e.target.value)}
        />
        <button className={classes.submitButton} onClick={() => launchSetup()}>
          <p className={classes.buttonText}>Solve</p>
        </button>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    marginTop: 10,
    marginLeft: 50,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "50%",
  },
  captcha: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "200px !important",
  },
  input: {
    textAlign: "center",
    padding: "8px 0px",
    height: 30,
    width: 300,
  },
  submitButton: {
    background: '#f84b67',
    border: 'none',
    height: 50,
    width: 80,
    fontSize: 16,
    color: 'white',
    fontWeight: '300',
    cursor: 'pointer',
  },
  horizContainer:{
    display: 'flex',
    flexDirection: 'row',
  }
});
