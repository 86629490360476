export const launchValidation = async (type, value) => {
  switch (type) {
    case "hash":
      var startValue = value.substring(0, 2);
      console.log(startValue);
      if (value.length >= 40 && startValue === "0x") {
        return value;
      } else {
        throw new Error(
          "Invalid Token. Please make sure you are using a real token contract address"
        );
      }
    case "project":
      if (value.tokenName === "") {
        throw new Error(
          "Project Name is empty, to continue please provide a name for your project"
        );
      } else if (value.tokenSymbol === "") {
        throw new Error(
          "Project Token Symbol is empty, to continue please provide a token symbol for your project"
        );
      } else if (
        value.tokenTelegram === "" ||
        /(t)\.me\/([a-zA-Z0-9_-]*)\/?$/g.test(value.tokenTelegram) === false
      ) {
        throw new Error(
          "Invalid Telegram Link, to continue please provide a telegram link for your project"
        );
      } else if (
        value.tokenWebsite === "" ||
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g.test(
          value.tokenWebsite
        ) === false
      ) {
        throw new Error(
          "Invalid Website Link, to continue please provide a website link for your project"
        );
      } else if (value.tokenDescription === "") {
        throw new Error(
          "Project description is empty, to continue please provide a description for your project"
        );
      } else if (
        value.buyTax === "" ||
        value.buyTax > 100 ||
        value.buyTax <= 0 ||
        /^\d+$/.test(value.buyTax) === false
      ) {
        throw new Error(
          "Invalid Buy Tax. Buy Tax has to be less than 100 and greater than 0"
        );
      } else if (
        value.sellTax === "" ||
        value.sellTax > 100 ||
        value.sellTax <= 0 ||
        /^\d+$/.test(value.sellTax) === false
      ) {
        throw new Error(
          "Invalid Sell Tax. Sell Tax has to be less than 100 and greater than 0"
        );
      } else if (
        value.maxTX.length !== 0 &&
        (value.maxTX > 100 || value.maxTX <= 0)
      ) {
        throw new Error(
          "Invalid Max Transaction. Max TX has to be less than 100 and greater than 0 or empty"
        );
      } else if (
        // value.maxWallet === "" ||
        // (value.maxWallet > 100 || value.maxWallet <= 0) ||
        // /^\d+$/.test(value.maxWallet) === false
        value.maxTX.length !== 0 &&
        (value.maxWallet > 100 || value.maxWallet <= 0)
      ) {
        throw new Error(
          "Invalid Max Wallet. Max Wallet has to be less than 100 and greater than 0 or empty"
        );
      } else {
        return value;
      }

    default:
      break;
  }
};
