import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Modal } from "@mui/material";
import logo from "../assets/icons/deBotLogoTransparent.png";
import {
  useMoralis,
} from "react-moralis";
import { BallTriangle } from "react-loader-spinner";

export const ConfirmationModal = ({
  isOpen,
  projectToLaunch,
  projectId,
  handleCloseModal,
}) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(null);

  const { id } = projectToLaunch;

  const {
    Moralis,
  } = useMoralis();

  const launch = async () => {
    setIsLoading(true);
    const Launch = Moralis.Object.extend("Launches");

    const query = new Moralis.Query(Launch);
    query.equalTo("objectId", id);
    const results = await query.find();

    if (results) {
      const object = results[0];
      object.set("isLaunched", true);
      await object.save().finally(() => window.location.reload(false));
    }
  };

  return (
    <Modal className={classes.modal} open={isOpen}>
      <div className={classes.modalContainer}>
        {isLoading ? (
          <BallTriangle width={90} color="#f84b67" arialLabel="loading" />
        ) : (
          <div className={classes.infoContainer}>
            <div className={classes.headerLogo}>
              <img src={logo} style={{ width: 250, marginLeft: -25 }} alt="DebotBSC Logo" />
            </div>
            <h2 className={classes.subTitle}>Confirm Contract Reveal?</h2>
            <div
              style={{
                height: 1,
                width: "100%",
                background: "gray",
                marginBottom: 30,
                marginTop: 8,
              }}
            ></div>
            <p className={classes.description}>
              <span style={{ color: "red", fontWeight: "500" }}>
                Clicking confirm{" "}
              </span>{" "}
              will make the contract address available for anyone to access once
              they complete the anti-bot captcha
            </p>
            <p className={classes.description}>
              <span style={{ color: "red", fontWeight: "500" }}>
                Users don't{" "}
              </span>{" "}
              have to refresh their page to access the contract once you confirm
              the reveal.
            </p>

            <div className={classes.horizContainer}>
              <div
                className={classes.control}
                style={{ background: "#f84b67" }}
                onClick={launch}
              >
                <h2 className={classes.controlText}>Confirm</h2>
              </div>
              <div
                className={classes.control}
                style={{ background: "#3c3e53" }}
                onClick={handleCloseModal}
              >
                <h2 className={classes.controlText}>Decline</h2>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

const useStyles = makeStyles({
  headerLogo: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    height: "50vh",
    width: "35vw",
    background: "#27293d",
    borderRadius: 12,
  },
  closeButton: {
    position: "absolute",
    height: 50,
    width: 50,
    borderRadius: 100,
    backgroundColor: "tomato",
    top: 20,
    right: 20,
  },
  input: {
    color: "white",
    width: "95%",
    fontSize: 16,
    padding: 14,
    border: "none",
    background: "#242535",
    borderRadius: 8,
    marginTop: 8,
    marginBottom: 8,
  },
  largeInput: {
    color: "white",
    width: "95%",
    fontSize: 16,
    padding: 14,
    border: "none",
    background: "#242535",
    borderRadius: 8,
    marginTop: 8,
    marginBottom: 8,
  },
  formContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  launchSelector: {
    marginTop: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: 530,
    height: 50,
  },
  selectedLaunchButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "48%",
    background: "tomato",
  },
  launchButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "48%",
    border: "2px solid tomato",
  },
  buttonText: {
    color: "white",
    fontSize: 16,
    textTransform: "uppercase",
    letterSpacing: 6,
  },
  submitButton: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "tomato",
    width: 440,
    height: 60,
  },
  infoContainer: {
    height: "90%",
    width: "85%",
  },
  selectionContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    marginTop: 15,
    color: "white",
    fontSize: 42,
    fontWeight: "600",
  },
  subTitle: {
    color: "white",
    fontWeight: "300",
    marginTop: 20,
  },
  description: {
    marginBottom: 20,
    fontSize: 18,
    fontWeight: "400",
    color: "white",
  },
  selectionButton: {
    marginTop: 30,
    height: 300,
    width: "49%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#31334c",
    borderRadius: 12,
  },
  selectionText: {
    color: "white",
    fontWeight: "400",
  },
  spreadForm: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    height: 150,
    width: "80%",
    marginTop: 15,
  },
  smInputContainer: {
    width: "40%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: 50,
  },
  smInput: {
    color: "white",
    width: "30%",
    fontSize: 16,
    padding: 14,
    border: "none",
    background: "#242535",
    borderRadius: 8,
    marginTop: 8,
    marginBottom: 8,
  },
  smInputLabel: {
    color: "gray",
    fontWeight: "300",
    marginRight: 8,
    width: 85,
  },
  horizContainer: {
    marginTop: 40,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  control: {
    width: "48%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 60,
    cursor: "pointer",
  },
  controlText: {
    color: "white",
    fontWeight: "400",
  },
});
